@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap");

#root {
    background: #2F5C99;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    /*overflow-wrap: break-word;*/
    /*word-wrap: break-word;*/
    /*word-break: break-all;*/
    /*hyphens: auto;*/
}

img {
    user-select: none;
}

a {
    cursor: pointer;
    color: #00BFFE;;
}
